@media all and (min-width: 480px) {
    .Login {
        padding: 20px 0;
    }

        .Login form {
            margin: 0 auto;
            max-width: 320px;
        }
}
