.modal-container {
    background: rgba(0, 0, 0, 0.5);
    background-color: rgba(0,0,0,0.7);
}


.modal-header-gw {
    background: #166A96;
    background: -webkit-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#166A96),to(#166A96));
    background: -o-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: linear-gradient(to bottom, #166A96 0%,#166A96 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#166A96', endColorstr='#166A96',GradientType=0 );
    height: 40px;
    line-height: 20px;
    padding: 12px 20px;
    text-align: center;
    border-radius: 0;
    position: sticky;
    top: 0;
    left: 10px;
    width: 100%;
    z-index: 99999;
}

.modal-wrapper-gw {
    position: absolute;
    top: 0;
    left: 20%;
    z-index: 999999999;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 70%;
    height: 87vh;
    overflow-y: scroll;
}

.modal-footer-gw {
    background: #166A96;
    background: -webkit-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#166A96),to(#166A96));
    background: -o-linear-gradient(top, #166A96 0%,#166A96 100%);
    background: linear-gradient(to bottom, #166A96 0%,#166A96 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#166A96', endColorstr='#166A96',GradientType=0 );
    height: 40px;
    line-height: 30px;
    text-align: right;
    justify-content: right;
    width: 100%;
    bottom:0;
    position: fixed;
    border-radius: 0;
}

.close-modal-btn-gw {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 20px;
    margin: 0;
}

.modal-button-gw {
    border: 0;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    line-height: normal;
    margin-right: 5px;
    margin-top:4px;
    margin-bottom:4px;
    right: 0;
    bottom: 0;
    position: absolute;
}

.modal-body-gw {
    padding: 10px 15px;
    text-align: center;
    position: absolute;
    overflow-y: hidden;
}
